<!-- 学员管理 -->
<template>
  <div class="StudentAdmin">
    <!-- 上面部分 -->
    <div class="top">
      <span style="font-size: 16px; font-weight: bold">学员管理</span>
      <el-button class="el-btn" size="small" @click="goBack"> 返回 </el-button>
    </div>
    <!-- 信息表单 -->
    <!-- <el-form ref="form" :model="form" inline class="form" id="form" label-width="90px">
            <el-form-item label="考场名称">
                <el-input :value="form.roomName" placeholder="" disabled></el-input>
            </el-form-item>
            <el-form-item label="考场人数">
                <el-input :value="form.roomPeople" placeholder="" disabled></el-input>
            </el-form-item>
            <el-form-item label="实际人数">
                <el-input :value="form.actual" placeholder="" disabled></el-input>
            </el-form-item>
        </el-form> -->
    <!-- 按钮 -->
    <div class="" style="margin-left: 21px">
      <el-button
        size="small"
        icon="el-icon-refresh"
        style="background-color: #2c3e50; border-radius: 3px"
        @click="refresh"
      >
        <!-- 刷新 -->
      </el-button>
      <!-- <button class="print">打印预览</button> -->
      <!-- <button class="derive">导出到Excel</button> -->
      <button class="addstu" @click="addstu">添加学员</button>
    </div>
    <!-- 表格 -->
    <el-table
      :data="tableData"
      border
      stripe
      style="width: 98%; flex: 1"
      height="650"
      id="out-table"
    >
      <el-table-column type="index" label="序号" align="center" width="80">
      </el-table-column>
      <el-table-column
        prop="certificate_no"
        sortable
        label="准考证号"
        align="center"
        width="200"
      >
      </el-table-column>
      <el-table-column
        prop="username"
        sortable
        label="学员姓名"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="pinyin_name"
        sortable
        label="拼音名"
        align="center"
        width="150"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        prop="gender"
        sortable
        label="性别"
        align="center"
        width="80"
      >
      </el-table-column>
      <el-table-column
        prop="birthdate"
        sortable
        label="出生日期"
        align="center"
        width="150"
      >
      </el-table-column>
      <el-table-column
        prop="nation"
        sortable
        label="民族"
        align="center"
        width="100"
      >
      </el-table-column>
      <el-table-column
        prop="nation2"
        sortable
        label="国籍"
        align="center"
        width="100"
      >
      </el-table-column>
      <el-table-column
        prop="org_name"
        sortable
        label="机构名称"
        align="center"
        width="150"
      >
      </el-table-column>
      <el-table-column
        prop="status2"
        sortable
        label="安排状态"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="course_name"
        sortable
        label="学科"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="exam_level"
        sortable
        label="等级值"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="exam_status"
        sortable
        label="考试状态"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="certificatecopy_price"
        sortable
        label="副证费"
        align="center"
        width="130"
      >
      </el-table-column>
      <el-table-column label="操作" align="center" width="200" fixed="right">
        <template slot-scope="scope">
          <el-button
            class="turn"
            size="mini"
            @click="turnRoom(scope.$index, scope.row)"
          >
            转考场
          </el-button>
          <el-button
            type="danger"
            icon="el-icon-delete"
            size="mini"
            @click="dlet(scope.$index, scope.row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 转考场弹框 -->
    <el-dialog
      :center="true"
      width="550px"
      :visible.sync="dialogFormVisible"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <div
        class=""
        style="
          font-size: 20px;
          font-weight: bold;
          color: #333;
          text-align: center;
          margin-bottom: 30px;
        "
      >
        考生转考场
      </div>
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        inline
        class="form-1"
        label-width="140px"
      >
        <el-form-item label="选择新考场" prop="newRoom">
          <el-select
            v-model="ruleForm.newRoom"
            filterable
            placeholder="请输入并选择新考场"
            :clearable="true"
          >
            <el-option
              v-for="item in ruleForm.newRoomChose"
              :key="item.id"
              :label="item.area_name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-divider></el-divider>
      </el-form>
      <div class="footer">
        <el-button
          style="background-color: #18bc9c; color: #ffffff"
          @click="submitForm('ruleForm')"
        >
          提交
        </el-button>
        <el-button
          style="background-color: #f5f5f5; color: #666"
          @click="resetForm('ruleForm')"
          >取消</el-button
        >
      </div>
    </el-dialog>

    <!-- 单科考试添加学员 -->
    <el-dialog
      :visible.sync="dlog"
      width="1024px"
      height="700px"
      class="dlog"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <el-form
        ref="form2"
        :model="form2"
        inline
        class="form"
        id="form"
        label-width="100px"
      >
        <!-- <el-form-item label="查询日期">
                    <el-date-picker value-format="yyyy-MM-dd" v-model="form2.dateQuery" type="daterange" range-separator="至"
                        start-placeholder="开始日期" end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item> -->
        <el-form-item label="关键字">
          <el-input
            v-model="form2.search"
            placeholder="请输入姓名/报名单号/身份证/准考证号"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="单据状态">
                    <el-select v-model="form2.condition" placeholder="请选择单据状态" :clearable="true">
                        <el-option v-for="item in form2.conditionChoose" :key="item.id" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item> -->
        <el-form-item label="考试大类">
          <el-select
            v-model="form2.exam"
            filterable
            placeholder="请选择考试大类"
            :clearable="true"
          >
            <el-option
              v-for="item in form2.examOpt"
              :key="item.id"
              :label="item.exam_name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择科目">
          <el-select
            v-model="form2.subject"
            filterable
            placeholder="请选择科目"
            :clearable="true"
          >
            <el-option
              v-for="item in form2.subjectlist"
              :key="item.id"
              :label="item.course_name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择机构">
          <el-select
            v-model="form2.mechanism"
            filterable
            placeholder="请输入并选择"
            :clearable="true"
          >
            <el-option
              v-for="item in form2.mechanismChoose"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="报考等级">
          <el-select
            v-model="form.grade"
            placeholder="请选择"
            :clearable="true"
          >
            <el-option
              v-for="item in form.gradeOpt"
              :key="item.id"
              :label="item.level"
              :value="item.level"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="异常">
          <el-select
            v-model="form2.abnormal"
            placeholder="请选择"
            :clearable="true"
          >
            <el-option
              v-for="item in form2.abnormalChoose"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <div class="" style="margin-top: 5px">
          <el-button
            size="small"
            style="background: #18bc9c; color: #ffffff"
            @click="query()"
          >
            查询
          </el-button>
          <el-button
            size="small"
            style="background: #fafafa; color: #666666"
            @click="Reset()"
          >
            重置
          </el-button>
        </div>
      </el-form>
      <el-table
        :data="dlogData"
        border
        stripe
        @selection-change="checkAll"
        style="width: 960px; flex: 1"
        height="650"
      >
        <!-- 复选按钮 -->
        <el-table-column type="selection" align="center" width="50">
        </el-table-column>
        <el-table-column type="index" label="序号" align="center" width="60">
        </el-table-column>
        <el-table-column
          prop="status"
          label="单据状态"
          align="center"
          width="80"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.status == 0 || scope.row.status == ''"></span>
            <span v-else-if="scope.row.status == 1">草稿</span>
            <span v-else-if="scope.row.status == 2">报考</span>
            <span v-else-if="scope.row.status == 3">准考</span>
            <span v-else-if="scope.row.status == 4">考试OK</span>
            <span v-else-if="scope.row.status == 5">取消</span>
            <span v-else-if="scope.row.status == 6">退款取消</span>
            <span v-else-if="scope.row.status == 7">延期</span>
            <span v-else-if="scope.row.status == 8">缺考</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="enroll_number"
          label="单号"
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="certificate_no"
          label="准考证号"
          align="center"
          width="200"
        >
        </el-table-column>
        <el-table-column prop="name" label="姓名" align="center" width="120">
        </el-table-column>
        <el-table-column
          prop="idcard"
          label="身份证号"
          align="center"
          width="200"
        >
        </el-table-column>
        <el-table-column prop="course" label="科目" align="center" width="100">
        </el-table-column>
        <el-table-column
          prop="exam_level"
          label="等级"
          align="center"
          width="80"
        >
        </el-table-column>
      </el-table>
      <!--分页 -->
      <el-pagination
        @size-change="handleSizeChange1"
        @current-change="handleCurrentChange1"
        :current-page="currentPage1"
        :page-sizes="[20, 50, 100, 200, 500, 1000]"
        :page-size="10"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total1"
      >
      </el-pagination>
      <div class="footer">
        <el-button
          style="background-color: #18bc9c; color: #ffffff"
          @click="submit('form2')"
        >
          提交
        </el-button>
        <el-button
          style="background-color: #f5f5f5; color: #666"
          @click="reset('form2')"
          >取消</el-button
        >
      </div>
    </el-dialog>

    <!-- 综合考场添加学员 -->
    <el-dialog
      :center="true"
      width="550px"
      :visible.sync="dialog"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <div
        class=""
        style="
          font-size: 20px;
          font-weight: bold;
          color: #333;
          text-align: center;
          margin-bottom: 30px;
        "
      >
        添加学员
      </div>
      <el-form
        ref="ruleForm_1"
        :model="ruleForm_1"
        :rules="rule"
        inline
        class="form-1"
        label-width="140px"
      >
        <!-- <el-form-item label="搜索学员" prop="addStudent" v-if="this.$route.query.exam_type==1">
                    <el-select v-model="ruleForm_1.addStudent" filterable remote 
                    placeholder="请输入并选择学员姓名" :clearable="true"
                        :remote-method="students" @change="studentChange">
                        <el-option v-for="item in ruleForm_1.addStudentChose" :key="item.id"
                         :label="`${item.name}-${item.idcard}-${item.course}-${item.exam_level}`"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item> -->
        <el-form-item label="选择机构" prop="mechanism">
          <el-select
            v-model="ruleForm_1.mechanism"
            filterable
            placeholder="请输入并选择机构"
            :clearable="true"
          >
            <el-option
              v-for="item in ruleForm_1.mechanismChoose"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-divider></el-divider>
      </el-form>
      <div class="footer">
        <el-button
          style="background-color: #18bc9c; color: #ffffff"
          @click="submit('ruleForm_1')"
        >
          提交
        </el-button>
        <el-button
          style="background-color: #f5f5f5; color: #666"
          @click="reset('ruleForm_1')"
          >取消</el-button
        >
      </div>
    </el-dialog>
    <!--分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[20, 50, 100, 200, 500, 1000]"
      :page-size="10"
      background
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        roomName: "", //考场名称
        roomPeople: "", //考场人数
        actual: "", //实际人数
        grade: "", //报考等级
        gradeOpt: [], //报考等级选项
      },
      currentPage: 1,
      currentLimit: 20,
      total: 0,
      tableData: [],
      dialogFormVisible: false,
      ruleForm: {
        id: "", //id
        newRoom: "", //新考场
        newRoomChose: [], //新考场选择
        number: "", //序号
        cardNum: "", //转考证号
        institution: "", //机构名称
        name: "", //学生姓名
        pinyin: "", //拼音名
        sex: "", //性别
        birthday: "", //出生日期
        nationality: "", //国籍
        nation: "", //民族
        planState: "", //安排状态
        planStateChose: [
          {
            label: "有效",
            value: 1,
          },
        ], //安排状态选择
        explain: "", //说明
      },
      rules: {
        newRoom: [
          {
            required: true,
            message: "请选择新考场",
            trigger: "change",
          },
        ],
      },
      ruleForm_1: {
        addStudent: "", //添加学员
        addStudentChose: [], //添加学员选择
        mechanism: "", //机构
        mechanismChoose: [],
      },
      rule: {
        addStudent: [
          {
            required: true,
            message: "请选择学员",
            trigger: "change",
          },
        ],
        mechanism: [
          {
            required: true,
            message: "请选择机构",
            trigger: "change",
          },
        ],
      },
      bool: false,
      dialog: false,
      dlog: false,
      dlogData: [],
      form2: {
        dateQuery: [], //查询日期
        fullName: "", //人员姓名
        search: "", //关键字
        condition: "", //单据状态
        conditionChoose: [
          {
            value: 0,
            label: "全部",
          },
          {
            value: 1,
            label: "草稿",
          },
          {
            value: 2,
            label: "报考",
          },
          {
            value: 3,
            label: "准考",
          },
          {
            value: 4,
            label: "考试OK",
          },
          {
            value: 5,
            label: "取消",
          },
          {
            value: 6,
            label: "退款取消",
          },
          {
            value: 7,
            label: "延期",
          },
          {
            value: 8,
            label: "缺考",
          },
        ], //单据状态选择
        mechanism: "", //机构
        mechanismChoose: [], //机构选择
        abnormal: 0, //异常
        abnormalChoose: [
          { value: 0, label: "全部" },
          { value: 1, label: "无科目报名" },
          { value: 2, label: "无机构报名" },
        ], //异常选择
        exam: "", //考试大类
        examOpt: [], //考试大类选项
        subject: "", //科目id
        subjectlist: [], //选择科目
      },
      currentPage1: 1,
      currentLimit1: 20,
      total1: 0,
      stuArr: [],
    };
  },
  created() {
    this.getList();
    this.getList1();
    // 考场数据
    this.$request({
      url: "/api/examarrange/list",
      method: "POST",
      data: {
        page: 1,
        limit: 100,
      },
    }).then((res) => {
      console.log(res);
      if (res.code == 1) {
        this.ruleForm.newRoomChose = res.data.list;
      }
    });

    // 选择机构
    this.$request({
      url: "/api/mechanism/list",
      method: "POST",
      data: {
        page: 1,
        limit: 1000,
      },
    }).then((res) => {
      // console.log("选择机构",res.data.list)
      this.ruleForm_1.mechanismChoose = res.data.list;
      this.form2.mechanismChoose = res.data.list;
    });
    this.$request({
      url: "/api/course/list",
      method: "POST",
      data: {
        //  course_id:this.$route.query.course_id,
      },
    }).then((res) => {
      console.log(res.data.list, "科目");
      this.form2.subjectlist = res.data.list;
    });

    // 选择等级
    this.$request({
      url: "/api/level/list",
      method: "POST",
      data: {
        page: 1,
        limit: 1000,
      },
    }).then((res) => {
      console.log("选择等级", res.data.list);
      this.form.gradeOpt = res.data.list;
    });

    // 考试大类
    this.$request({
      url: "/api/ExamCategory/list",
      method: "POST",
      data: {
        page: 1,
        limit: 1000,
      },
    }).then((res) => {
      // console.log(res,"考试大类")
      if (res.code == 1) {
        this.form2.examOpt = res.data.list;
      }
    });
    console.log(this.stuArr, 123);
  },
  methods: {
    getList1() {
      this.$request({
        url: "/api/examenroll/list",
        method: "POST",
        data: {
          page: this.currentPage1,
          limit: this.currentLimit1,
          dateQuery: this.form2.dateQuery, //查询时间
          search: this.form2.search, //关键字
          status: 3, //单据状态
          type: this.form2.abnormal, //异常
          apply_category: this.form2.exam,
          course_id: this.form2.subject,
          org_id: this.form2.mechanism,
          exam_level: this.form.grade,
          is_examarea: 1,
        },
      }).then((res) => {
        console.log("列表", res);
        this.dlogData = res.data.list;
        console.log(this.dlogData);
        this.total1 = res.data.count;
      });
    },
    getList() {
      this.$request({
        url: "/api/examarrange/student",
        method: "POST",
        data: {
          id: this.$route.query.id,
          page: this.currentPage,
          limit: this.currentLimit,
        },
      }).then((res) => {
        // console.log(res, "学员")
        if (res.code == 1) {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    handleSizeChange(val) {
      this.currentLimit = val;
      this.getList();
      // console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
      // console.log(`当前页: ${val}`);
    },
    goBack() {
      //返回
      this.$router.go(-1);
    },
    // 刷新
    refresh() {
      this.$router.push({
        path: "/home/newpage", //空页面路由
        query: {
          path: this.$route.path,
        },
      });
    },
    addstu() {
      //添加学员
      if (this.$route.query.exam_type == 1) {
        this.dlog = true;
      } else if (this.$route.query.exam_type == 2) {
        this.dialog = true;
      }
    },
    turnRoom(index, row) {
      this.dialogFormVisible = true;
      this.ruleForm.id = row.id;
      console.log(row);
    },
    submitForm(ruleForm) {
      //审核提交
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          // alert('submit!');
          // console.log(this.ruleForm)
          if (this.bool) {
            return;
          }
          this.bool = true;
          this.$request({
            url: "/api/examarrange/area_change",
            method: "POST",
            data: {
              id: this.ruleForm.id,
              exam_eid: this.ruleForm.newRoom,
            },
          }).then((res) => {
            if (res.code == 1) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              this.dialogFormVisible = false;
            } else {
              this.$message({
                message: res.msg,
                type: "error",
              });
              this.dialogFormVisible = false;
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(ruleForm) {
      //审核取消
      this.dialogFormVisible = false;
      this.$refs[ruleForm].resetFields();
    },
    checkAll(e) {
      this.stuArr = [];
      console.log(e);
      e.forEach((item) => {
        this.stuArr.push(item.id);
      });
      console.log(this.stuArr);
    },

    //学生数据
    students(e) {
      // console.log(e);
      this.$request({
        url: "/api/examenroll/list",
        method: "POST",
        data: {
          is_exam: 1,
          search: e,
          course_id: this.$route.query.course_id,
          exam_level: this.$route.query.exam_level,
        },
      }).then((res) => {
        console.log("学生数据", res.data.list);
        this.ruleForm_1.addStudentChose = res.data.list;
      });
    },
    studentChange(e) {
      //学生数据
      // console.log(e)
      let result = this.ruleForm_1.addStudentChose.find(
        (item) => item.username == e
      );
      // console.log(result)
      // this.form.student_id=result.id
      // this.form.studentName=result.username
      // this.form.idcard=result.idcard
      // console.log(result.id,result.idcard)
    },
    submit(form) {
      //添加学员
      this.$refs[form].validate((valid) => {
        if (valid) {
          let type = this.$route.query.exam_type;
          if (type == 1) {
            this.$request({
              url: "/api/examarrange/student_to_exam",
              method: "POST",
              data: {
                id: this.$route.query.id,
                ids: this.stuArr,
              },
            }).then((res) => {
              if (res.code == 1) {
                this.$message({
                  message: res.msg,
                  type: "success",
                });
                this.dlog = false;
              } else {
                this.$message({
                  message: res.msg,
                  type: "error",
                });
                this.dlog = false;
              }
            });
          } else if (type == 2) {
            this.$request({
              url: "/api/examarrange/org_to_exam",
              method: "POST",
              data: {
                id: this.$route.query.id,
                org_id: this.ruleForm_1.mechanism,
              },
            }).then((res) => {
              if (res.code == 1) {
                this.$message({
                  message: res.msg,
                  type: "success",
                });
                this.dialog = false;
              } else {
                this.$message({
                  message: res.msg,
                  type: "error",
                });
                this.dialog = false;
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    reset(ruleForm_1) {
      //取消添加学员
      this.$refs[ruleForm_1].resetFields();
      this.dialog = false;
    },
    dlet(index, row) {
      this.$confirm("此操作将永久删除该数据，是否继续?", "提示", {
        confirmButtonText: "确定",
        acncelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$request({
          url: "/api/examarrange/clear_area",
          method: "POST",
          data: {
            id: row.id,
          },
        }).then((res) => {
          if (res.code == 1) {
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.tableData.splice(index, 1);
            this.getList();
          }
        });
      });
    },

    handleSizeChange1(val) {
      this.currentLimit1 = val;
      this.getList1();
      // console.log(`每页 ${val} 条`);
    },
    handleCurrentChange1(val) {
      this.currentPage1 = val;
      this.getList1();
      // console.log(`当前页: ${val}`);
    },
    query() {
      //查询
      this.currentPage1 = 1;
      this.getList1();
    },
    Reset() {
      //重置
      this.form2.dateQuery = []; //查询时间
      this.form2.search = ""; //关键字
      this.form2.condition = ""; //单据状态
      this.form2.mechanism = ""; //机构id
      this.form2.abnormal = 0; //异常
      this.form2.exam = "";
      this.form.grade = "";
      this.getList1();
    },
  },
};
</script>

<style scoped="scoped">
.StudentAdmin {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* 上面部分 */
.top {
  padding-top: 25px;
  margin: 0px 24px 33px 28px;
  display: flex;
  justify-content: space-between;
}

.el-btn {
  background: #18bc9c;
  padding-left: 25px;
  background-position: 5px 7px;
  display: inline-block;
  background-image: url(../../assets/back.png);
  background-repeat: no-repeat;
  color: #ffffff;
}

.el-form {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
}

.form::v-deep .el-form-item__label {
  color: #000000;
}

.form::v-deep .el-input__inner {
  background: #fff !important;
  width: 300px;
  height: 37px !important;
}

/* 刷新按钮 */
::v-deep .el-icon-refresh {
  font-weight: bold !important;
  color: #ffffff;
}

/* 打印预览 */
.print {
  display: inline-block;
  padding-left: 10px;
  width: 94px;
  height: 30px;
  border-radius: 3px;
  vertical-align: middle;
  background: url(../../assets/print.png) 8px 9px no-repeat #18bc9c;
  border: none;
  color: #ffffff;
  margin: 0px 10px;
}

/* 导出按钮 */
.derive {
  display: inline-block;
  width: 113px;
  height: 30px;
  padding-left: 10px;
  vertical-align: middle;
  background: url(../../assets/export.png) 6px 6px no-repeat #ff7300;
  color: #ffffff;
  border-radius: 3px;
  border: none;
  margin-right: 10px;
}

/* 添加学员 按钮*/
.addstu {
  display: inline-block;
  width: 77px;
  height: 30px;
  background-color: #29726c;
  color: #ffffff;
  vertical-align: middle;
  border-radius: 3px;
  border: none;
  margin-left: 10px;
  cursor: pointer;
}

/* 表格 */
.el-table {
  margin: 20px;
}

::v-deep .el-table__cell {
  color: #000;
  padding: 0;
  height: 30px;
}

/* 转考场按钮 */
.turn {
  border-radius: 3px;
  background: url(../../assets/turn.png) 5px 6px no-repeat #f0ad4e;
  color: #fff;
  padding-left: 25px;
}

/* 弹出框 */
::v-deep .el-dialog__header {
  /* padding: 0px !important; */
}
.StudentAdmin ::v-deep .el-dialog__body {
  padding: 25px 25px 30px !important;
}
.form-1 ::v-deep .el-input__inner {
  width: 300px;
  height: 37px;
  background: #fff;
  color: #000000;
}

.form-1 ::v-deep .el-form-item__label {
  color: #000000;
}

::v-deep .footer {
  margin-top: 30px !important;
  text-align: center !important;
  /* height: 50px !important; */
}

::v-deep.el-dialog__footer {
  padding: 20px !important;
}

/* 分页 */
.el-pagination {
  margin: 20px;
  margin-top: 0px;
}

::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #2c3e50 !important;
}
</style>
